body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --colorBorder: #c4c4c4;
  --border: 1px solid var(--colorBorder);
  --dashedBorder: 1px dashed var(--colorBorder);
  --colorFont: #111;
  --colorPrimary: #df6732;
  --colorFontHover: #222;
  --colorWarning: #e32343;
}

* {
  box-sizing: border-box;
}

.Button-module-Hio-gG-button {
  margin: none;
  border: var(--border);
  cursor: pointer;
  background-color: #fffc;
  border-radius: 3px;
  outline: none;
  padding-block: 8px;
  padding-inline: 24px;
}

.Button-module-Hio-gG-button:hover {
  background-color: #c8c8c8cc;
}

.Button-module-Hio-gG-button:active {
  background-color: #646464cc;
}

.Button-module-Hio-gG-button:focus {
  outline: 1px solid #00f;
}

.Button-module-Hio-gG-default {
  background-color: #fff;
}

.Button-module-Hio-gG-primary {
  color: #fff;
  background-color: #000;
}

.Button-module-Hio-gG-primary:hover, .Button-module-Hio-gG-primary:focus {
  background-color: #232323;
}

.Button-module-Hio-gG-primary:active {
  background-color: #111;
}

.Button-module-Hio-gG-icon {
  background-color: #0000;
  border: none;
  padding: 0;
}

.Button-module-Hio-gG-tight {
  padding: 4px;
}

.Icon-module-r3YESG-md {
  height: 32px;
  width: 32px;
  display: block;
}

.Icon-module-r3YESG-pencil {
  background: url("pencil.fd791005.svg") 0 0 / contain;
}

.FileUpload-module-bRqugW-target {
  height: 150px;
  width: 100px;
  border: var(--dashedBorder);
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.FileUpload-module-bRqugW-input {
  opacity: 0;
  height: 0;
  max-height: 0;
  min-height: 0;
}

.FileUpload-module-bRqugW-preview {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.FileUpload-module-bRqugW-editButton {
  z-index: 1;
  position: absolute;
  top: 4px;
  right: 4px;
}



.Loader-module-b9hgoq-container {
  height: 100%;
  width: 100%;
}

.EditQuizConfig-module-55uIOG-container {
  width: 100%;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  padding-block: 16px;
  display: flex;
}

.EditQuizConfig-module-55uIOG-header {
  justify-content: space-between;
  padding-inline: 16px;
  display: flex;
}

.EditQuizConfig-module-55uIOG-body {
  flex-direction: column;
  gap: 16px;
  padding-inline: 16px;
  display: flex;
}

.EditQuizConfig-module-55uIOG-title {
  font-size: 32px;
}

.EditQuizConfig-module-55uIOG-iconSettings {
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.EditQuizConfig-module-55uIOG-buttons {
  gap: 16px;
  display: flex;
}

.QuizEdit-module-MLb2Ra-container {
  width: 100%;
  flex: 1;
  display: flex;
}

.QuizEdit-module-MLb2Ra-sideBar {
  height: 100%;
  width: 300px;
  box-sizing: border-box;
  background-color: #efefef;
  flex-direction: column;
  padding-block: 16px;
  display: flex;
  overflow-y: auto;
}

.QuizEdit-module-MLb2Ra-spaceTaker {
  flex: 100%;
}

.QuizEdit-module-MLb2Ra-quizIcon {
  height: 100px;
}

.QuizEdit-module-MLb2Ra-rows {
  min-height: 0;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.QuizEdit-module-MLb2Ra-row {
  width: 100%;
  box-sizing: border-box;
  min-height: 0;
  justify-content: space-between;
  padding: 16px;
  display: flex;
}

.QuizEdit-module-MLb2Ra-row:not(:last-child) {
  border-block-end: var(--border);
}

.QuizEdit-module-MLb2Ra-summary {
  align-items: start;
  gap: 8px;
  display: flex;
}

.QuizEdit-module-MLb2Ra-rowHeader {
  padding-block-end: 8px;
  font-size: 24px;
}

.QuizEdit-module-MLb2Ra-action {
  opacity: 0;
}

.QuizEdit-module-MLb2Ra-row:hover .QuizEdit-module-MLb2Ra-action, .QuizEdit-module-MLb2Ra-row:focus-within .QuizEdit-module-MLb2Ra-action {
  opacity: 1;
}

.QuizEdit-module-MLb2Ra-body {
  flex: 1;
}

.Header-module-kSrBdq-container {
  background-color: var(--colorPrimary);
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.Header-module-kSrBdq-title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Homemade Apple;
  font-size: 1em;
  text-decoration: none;
}

.Header-module-kSrBdq-login {
  color: #fff;
  text-decoration: none;
}

.Header-module-kSrBdq-login:hover {
  text-decoration: underline;
}

.UserDropdown-module-idMMhq-container {
  position: relative;
}

.UserDropdown-module-idMMhq-userIcon {
  height: 28px;
  width: 28px;
  color: #000000b3;
  background-color: #e5e5e5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.UserDropdown-module-idMMhq-userIcon:after {
  content: "?";
  font-size: 20px;
  font-weight: 700;
}

.UserDropdown-module-idMMhq-dropdown {
  border: var(--border);
  background-color: #fff;
  border-radius: 3px;
  padding-block: 8px;
  padding-inline: 24px;
  position: absolute;
  top: calc(100% + 8px);
  left: 8px;
}

.UserDropdown-module-idMMhq-link {
  padding-block-start: 8px;
  display: block;
}

.QuizManagementOptions-module-RGfoZW-container {
  border-block-end: var(--border);
  justify-content: flex-end;
  margin-bottom: 16px;
  padding-block: 8px;
  padding-inline: 16px;
  display: flex;
}

.ModalHeader-module-ht5dXG-container {
  border-bottom: var(--border);
  padding-block: 16px;
  padding-inline: 24px;
  font-size: 32px;
}

.CreateQuizModal-module-oqg6Ma-container {
  color: #fff;
  background-color: #000;
}

.AddQuiz-module-D0LpFq-container {
  height: 200px;
  width: 150px;
  border: 1px dashed var(--colorBorder);
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

.AddQuiz-module-D0LpFq-plus {
  font-size: 24px;
}

.Quizzes-module-KGwiCa-container {
  flex-wrap: wrap;
  gap: 32px;
  padding: 16px;
  display: flex;
}

.Quizzes-module-KGwiCa-quiz {
  border: var(--border);
  width: 150px;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  display: flex;
}

.Quizzes-module-KGwiCa-icon {
  height: 150px;
  width: 100px;
}

.Quizzes-module-KGwiCa-icon img {
  height: 100%;
  width: 100%;
}

.Review-module-aBWyMa-container {
  padding: 16px;
}

.CreateAccount-module-d9Go4q-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.CreateAccount-module-d9Go4q-box {
  max-width: 90%;
  width: 500px;
  border: var(--border);
  background-color: #f3f3f3;
  border-radius: 3px;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  display: flex;
}

.CreateAccount-module-d9Go4q-container label {
  gap: 16px;
  display: flex;
}

.CreateAccount-module-d9Go4q-container input {
  flex: 1;
}

.CreateAccount-module-d9Go4q-footer {
  justify-content: flex-end;
  gap: 8px;
  display: flex;
}

.Login-module-LYEkSa-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Login-module-LYEkSa-loginBox {
  max-width: 90%;
  width: 500px;
  border: var(--border);
  background-color: #f3f3f3;
  border-radius: 3px;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  display: flex;
}

.Login-module-LYEkSa-title {
  align-self: center;
  padding-bottom: 16px;
  font-size: 1.5em;
}

.Login-module-LYEkSa-error {
  color: var(--colorWarning);
  text-align: center;
  justify-content: center;
  gap: 8px;
  display: flex;
}

.Login-module-LYEkSa-container label {
  gap: 16px;
  display: flex;
}

.Login-module-LYEkSa-container input {
  flex: 1;
}

.Login-module-LYEkSa-link {
  align-self: flex-end;
}

.Login-module-LYEkSa-exclamation:after {
  background-color: var(--colorWarning);
  color: #fff;
  content: "!";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
}

.SubHeader-module-3RlBbG-container {
  border-block-end: var(--border);
  box-sizing: border-box;
  align-items: center;
  gap: 16px;
  padding-inline: 16px;
  display: flex;
  position: relative;
}

.SubHeader-module-3RlBbG-container a {
  color: var(--colorFont);
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding-block: 16px;
  text-decoration: none;
  display: flex;
}

a.SubHeader-module-3RlBbG-active {
  color: var(--colorPrimary);
}

.SubHeader-module-3RlBbG-activeIndicator {
  height: 4px;
  content: "";
  background-color: var(--colorPrimary);
  transition: width .3s cubic-bezier(.075, .82, .165, 1), left .3s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  bottom: 0;
}

.SubHeader-module-3RlBbG-container a:hover {
  color: var(--colorFontHover);
  text-decoration: none;
}

html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App-module-gjnKlq-App {
  height: 100%;
  flex-direction: column;
  display: flex;
}

/*# sourceMappingURL=index.726cfb86.css.map */
