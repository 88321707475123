body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --colorBorder: #c4c4c4;
  --border: 1px solid var(--colorBorder);
  --dashedBorder: 1px dashed var(--colorBorder);
  --colorFont: #111111;
  --colorPrimary: rgb(223, 103, 50);
  --colorFontHover: #222222;
  --colorWarning: #e32343;
}


* {
  box-sizing: border-box;
}